
import { computed, defineComponent, ref } from "vue";
import { useAlert } from "@/hooks/alert";
import { useRoute, useRouter } from "vue-router";
import { removeCpfMask } from "@/utils/stringFunctions";

import UpdateDocumentDTO from "@/dtos/UpdateBiometryDTO";
import TakePicture from "@/components/TakePicture.vue";
import Modal from "@/components/Modal.vue";
import UsuarioService from "@/services/UsuarioService";
import FaceMask from "@/components/FaceMask.vue";
import vm from "@/viewModels/MainViewModel"
import BiopassFacesDTO from "@/dtos/BiopassFacesDTO";
import { trackers } from "@/hooks/trackers";
import ProctoringService from "@/services/ProctoringService";


const DemoCaptureBiometryView = defineComponent({
    components: { TakePicture, Modal, FaceMask },
    setup() {
        const alert = useAlert();
        const { replace, go } = useRouter();
        const { params } = useRoute();
        //demo cannot be used in production
        process.env.NODE_ENV === 'production' ? replace("/login") : ""

        const loading = ref(false);
        const modalOpened = ref(true);
        const modalPageIndex = ref(0);

        const modalButtonText = computed(() => {
            switch (modalPageIndex.value) {
                case 0:
                    return "Continuar";
                case 1:
                    return "Próximo";
                case 2:
                    return "Iniciar captura";
                default:
                    return "";
            }
        });

        const check = async (data: { picture: string; reset: () => void }) => {

            loading.value = true;
            const picture = data.picture.substr(22, data.picture.length);

            const dataToSend: UpdateDocumentDTO = {
                faceImage: picture,
                cpf: removeCpfMask(params.cpf.toString())
            };

            if (vm.federativeUnitSelected != "CE") {
                const [request] = ProctoringService.UpdateUserFace(dataToSend);
                request.then(resp => {
                    replace({ name: "demo-finished" });
                    trackers.registerPageView("Demo finished", "/demo-finished")
                }).catch(error => {
                    trackers.registerError("Erro", error.toString())
                    alert({
                        message: error.toString(),
                        actions: [
                            {
                                title: "Voltar",
                                action: () => go(-1),
                            },
                            {
                                title: "Tentar novamente",
                                primary: true,
                                action: () => data.reset(),
                            },
                        ],
                    });
                })

            } else {


                const face = ref<BiopassFacesDTO[]>([{ "Face-1": picture }]);

                const personPayload = {
                    Person: {
                        CustomID: removeCpfMask(params.cpf.toString()),
                        Face: face.value
                    }
                }

                const [faceMatch] = UsuarioService.VerifyBiometric(personPayload);
                faceMatch.then(resp => {
                    replace({ name: "demo-finished" });
                    trackers.registerPageView("Demo finished", "/demo-finished")
                }).catch(error => {
                    trackers.registerError("Erro", error.toString());
                    const [request] = UsuarioService.CreateBiopassUserFace(personPayload);
                    request.then(() => {
                        replace({ name: "demo-finished" });
                        trackers.registerPageView("Demo finished", "/demo-finished")
                    }).catch(err => {
                        trackers.registerError("Erro", err.toString());
                        alert({
                            message: "Erro ao cadastrar biometria",
                            actions: [
                                {
                                    title: "Voltar",
                                    action: () => go(-1),
                                },
                                {
                                    title: "Tentar novamente",
                                    primary: true,
                                    action: () => data.reset(),
                                },
                            ],
                        });
                    });
                });
            }
        };

        return {
            loading,
            check,
            modalOpened,
            modalPageIndex,
            modalButtonText,
        };
    }
}
);

export default DemoCaptureBiometryView;
