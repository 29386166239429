
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import Loading from '@/components/Loading.vue';
import { trackers } from '@/hooks/trackers';

const TakePicture = defineComponent({
    components: { Loading },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Validação facial'
        },
        subtitle: {
            type: String,
            default: 'Encaixe seu rosto no formato e clique no botão abaixo'
        },
        maskText: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const camera = ref<HTMLVideoElement | null>(null);
        const cameraPlaceholder = ref<HTMLCanvasElement | null>(null);

        const picture = ref('');

        const startCapture = () => {
            const cameraContainer = camera.value as HTMLVideoElement;

            // eslint-disable-next-line no-undef
            const constraints: MediaStreamConstraints = {
                audio: false,
                video: {
                    facingMode: 'user',
                    aspectRatio: 1.777777778,
                    width: { min: 640, ideal: 640, max: 1920 },
                    height: { min: 400, ideal: 400 },
                }
            };

            navigator.mediaDevices.getUserMedia(constraints)
                .then((media: MediaStream) => {
                    cameraContainer.srcObject = media;
                    cameraContainer.play();
                })
                .catch((e) => {
                    trackers.registerStreamLog(constraints.peerIdentity!, "error", e.toString());
                });
        };

        const takePicture = () => {
            const cameraContainer = camera.value as HTMLVideoElement;
            const canvas = cameraPlaceholder.value as HTMLCanvasElement;
            canvas.width = cameraContainer.videoWidth;
            canvas.height = cameraContainer.videoHeight;

            canvas.getContext('2d')!.drawImage(cameraContainer, 0, 0, cameraContainer.videoWidth, cameraContainer.videoHeight);
            picture.value = canvas!.toDataURL('image/jpg');
            trackers.registerStreamLog(cameraContainer.id, "published");
        };

        const checkBiometry = () => {
            if (props.loading)
                return;
            context.emit('checkbiometry', { picture: picture.value, reset: () => picture.value = '' });
        };

        onMounted(() => startCapture());

        onBeforeUnmount(() => {
            const cameraContainer = camera.value as HTMLVideoElement;
            const stream = cameraContainer.srcObject as MediaStream;

            stream.getTracks().forEach(el => {
                el.stop();
            });

            cameraContainer.srcObject = null;
        });

        return { camera, picture, takePicture, cameraPlaceholder, checkBiometry };
    }
});

export default TakePicture;
